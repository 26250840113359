import React from 'react'

import HowCanWeHelpPanel from 'components/atoms/HowCanWeHelpPanel'
import NavPanel from 'components/atoms/NavPanel'

import './index.scss'

const Automotive = () => {
  return (
    <div className="automotive-content-container">
      <div className="container">
        <div className="content">
          <h3>Protect Your Family and Your New Investment<span className="block"></span></h3>
          <p>
            Are you sure you know who has all the keys to your new home? Our master locksmiths can replace door locks for every door in your new home with the latest security technology. Dispatched in under thirty minutes, our rekeying and security installation services safeguard you and your loved ones and ensure that you protect your biggest investment yet. Sleep sound in your new home behind the industry’s best locks and security systems provided by our certified residential locksmiths.
          </p>
          <p>
            Speak with a Residential Locksmith Today to Protect Your New Home!
          </p>
          <h4>Services</h4>
          <ul>
            <li>Door Lock Installation</li>
            <li>Security Installation</li>
            <li>Lock Replacement</li>
            <li>Door Lock Repair</li>
            <li>Rekeying Services</li>
            <li>Lockouts</li>
            <li>Garage Door Locks</li>
            <li>Home Security Systems</li>
            <li>All Lock Brands Serviced</li>
          </ul>
        </div>
        <div className="nav-container">
          <NavPanel />
          <HowCanWeHelpPanel />
        </div>
      </div>
    </div>
  )
}

export default Automotive
