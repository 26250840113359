import React from 'react'

import PhoneCTAButton from 'components/atoms/PhoneCTAButton'

import './index.scss'

const SimpleCTA = () => {
  return (
    <div className="simple-cta-container">
      <div className="container">
        <h3>Dispatch a Locksmith Today</h3>
        <PhoneCTAButton delegate="Footer" />
      </div>
    </div>
  )
}

export default SimpleCTA
