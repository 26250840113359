import React from 'react'

import './index.scss'

const About = () => {
  return (
    <div className="about-container">
      <div className="container">
        <h3>About<br />Locksmith Directory<span className="block"></span></h3>
        <p>
          Locksmithdirectory is a dispatch service that connects users with a network of local locksmiths. These technicians can help you resolve any lock or key issue promptly. Serving most metro areas in the US. Call our dispatch today to get and reliable service from a trusted professional.
        </p>
      </div>
    </div>
  )
}

export default About
