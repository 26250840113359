import React from 'react'

import { SiteContext } from 'context/site-context'

import { ReactComponent as CallCenterIcon } from 'svgs/help-operator.svg'

import BackgroundImage from 'images/how-can-we-help-tools-block.png'

import './index.scss'

const HowCanWeHelpPanel = props => {
  const [ state ] = React.useContext(SiteContext)

  return (
    <div className="how-can-we-help-panel-container" style={{
        background: `url(${BackgroundImage}) center center no-repeat`,
        backgroundSize: 'cover'
      }}>
      <CallCenterIcon />
      <h3>How Can We Help?</h3>
      <label>Call us 24/7</label>
      <a href={`tel:${state.phone}`} data-delegate="HowCanWeHelpPanel">{state.phone}</a>
    </div>
  )
}

export default HowCanWeHelpPanel
