import React from 'react'

import HowCanWeHelpPanel from 'components/atoms/HowCanWeHelpPanel'
import NavPanel from 'components/atoms/NavPanel'

import './index.scss'

const Automotive = () => {
  return (
    <div className="automotive-content-container">
      <div className="container">
        <div className="content">
          <h3>Get Your Keys and Get Back on the Road<span className="block"></span></h3>
          <p>
            Automobile and motorcycle locking systems can be finicky and prone to breaking down without proper maintenance. Keep your vehicle’s locking mechanisms up to date so that you never have to deal with an inconvenient car lockout. Our automotive locksmiths have the proper tools and equipment to service all of your car lock needs. And they’re available to address any kind of vehicle—24/7. From keyless-ignition vehicles and car key remotes to traditional keyed ignition, we’re prepared to take on any automotive locking system.
          </p>
          <p>
            Get your keys and get back on the road with our auto locksmith services!
          </p>
          <h4>Services</h4>
          <ul>
            <li>Keyless Ignition</li>
            <li>Car Lockout</li>
            <li>Lock replacements</li>
            <li>Car Lock Repair</li>
            <li>Keyed Ignition </li>
            <li>Ignition Repair and Replacement</li>
            <li>Service All Car Models </li>
          </ul>
        </div>
        <div className="nav-container">
          <NavPanel />
          <HowCanWeHelpPanel />
        </div>
      </div>
    </div>
  )
}

export default Automotive
