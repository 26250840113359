import React from 'react'

import HowCanWeHelpPanel from 'components/atoms/HowCanWeHelpPanel'
import NavPanel from 'components/atoms/NavPanel'

import './index.scss'

const CommercialContent = () => {
  return (
    <div className="commercial-content-container">
      <div className="container">
        <div className="content">
          <h3>Your Business - Locked and Secured<span className="block"></span></h3>
          <p>
            A business secured against risks and threats is a profitable one. Protect your business with commercial grade security from our master
            locksmiths. We cater to businesses of all sizes and provide peace-of-mind to business owners with our trusted high security installation
            services. Our certified commercial locksmiths will handle any security issues you might have such as lock replacements, lock repairs,
            upgrades, and master key systems.
          </p>
          <p>
            Our locksmiths are prompt, professional and will ensure confidence in your businesses’ locks and security. Make an appointment with us today!
          </p>
          <h4>Services</h4>
          <ul>
            <li>Security Installation Services</li>
            <li>Security Evaluations</li>
            <li>Commercial Security Systems</li>
            <li>Closed Circuit Surveillance</li>
            <li>Biometric lock installation</li>
            <li>Lock repairs</li>
            <li>Door handle repairs</li>
            <li>Door Lock Replacements</li>
            <li>Safe and Filing Cabinet Locks</li>
            <li>Master Key Systems</li>
          </ul>
        </div>
        <div className="nav-container">
          <NavPanel />
          <HowCanWeHelpPanel />
        </div>
      </div>
    </div>
  )
}

export default CommercialContent
