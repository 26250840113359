import React from 'react'

import cn from 'classnames'

import { SiteContext } from 'context/site-context'

import Header from 'components/sections/Header'
import HeroService from 'components/sections/HeroService'
import ResidentialContent from 'components/sections/ResidentialContent'
import PriceGuide from 'components/atoms/PriceGuide'
import Why from 'components/sections/Why'
import SimpleCTA from 'components/sections/SimpleCTA'
import Footer from 'components/sections/Footer'

import BackgroundImage from 'images/residential-locksmith-services-hero.jpg'

import './index.scss'

const priceList = [
  { label: 'Service Fee	',  category: 'Minimum Charge (Included As Part The Final Service Charge)	', price: '$29 (fixed)' },
  { label: 'All Lockout Services',  category: 'Commercial, Residential & Automotive (Car Lockout)', price: '$48 - $230' },
  { label: 'Locks Re-Key', category: 'Commercial & Residential', price: '$48 - $300' },
  { label: 'Lock Repair', category: 'Commercial & Residential', price: '$48 - $180' },
  { label: 'Key Extraction', category: 'Commercial & Residential', price: '$48 - $130' },
  { label: 'Lock Change', category: 'Residential, Commercial - For Basic Locks', price: '$54 - $230' },
  { label: 'New Lock Installation', category: '(Door without a hole for a lock)', price: '$114 - $230' },
]

const Residential = () => {
  const [ site, setSite ] = React.useContext(SiteContext)

  React.useEffect(() => {
    const updateHeaderState = () => {
      setSite({
        ...site,
        showHeader: window.scrollY > site.minHeaderScrollDistance,
      })
    }
    window.addEventListener('scroll', updateHeaderState)
    return () => {
      window.removeEventListener('scroll', updateHeaderState)
    }
  }, [site, setSite])
  return <div className={cn('page-wrapper animate', {isLoadingSiteData: site.isLoadingSiteData})}>
    <Header showHeader={site.showHeader} animate={true} />
    <HeroService backgroundImage={BackgroundImage} headline="Residential Locksmiths"/>
    <ResidentialContent />
    <div className="price-guide-wrapper">
      <PriceGuide priceList={priceList} />
    </div>
    <Why />
    <SimpleCTA />
    <Footer />
  </div>
}

export default Residential
