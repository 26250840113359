import React from 'react'

import cn from 'classnames'

import { SiteContext } from 'context/site-context'

import Header from 'components/sections/Header'
import HeroService from 'components/sections/HeroService'
import CommercialContent from 'components/sections/CommercialContent'
import Why from 'components/sections/Why'
import SimpleCTA from 'components/sections/SimpleCTA'
import Footer from 'components/sections/Footer'

import BackgroundImage from 'images/commercial-locksmith-services-hero.jpg'

import './index.scss'

const Commercial = () => {
  const [ site, setSite ] = React.useContext(SiteContext)

  React.useEffect(() => {
    const updateHeaderState = () => {
      setSite({
        ...site,
        showHeader: window.scrollY > site.minHeaderScrollDistance,
      })
    }
    window.addEventListener('scroll', updateHeaderState)
    return () => {
      window.removeEventListener('scroll', updateHeaderState)
    }
  }, [site, setSite])
  return <div className={cn('page-wrapper animate', {isLoadingSiteData: site.isLoadingSiteData})}>
    <Header showHeader={site.showHeader} animate={true} />
    <HeroService backgroundImage={BackgroundImage} headline="Commercial Locksmiths"/>
    <CommercialContent />
    <Why />
    <SimpleCTA />
    <Footer />
  </div>
}

export default Commercial
