import React from 'react'

import HowCanWeHelpPanel from 'components/atoms/HowCanWeHelpPanel'
import NavPanel from 'components/atoms/NavPanel'

import './index.scss'

const NewHomeContent = () => {
  return (
    <div className="new-home-content-container">
      <div className="container">
        <div className="content">
          <h3>Sleep Safe with Secure Locks<span className="block"></span></h3>
          <p>
            Your front door is the first line of defense in protecting what you hold most dear. Our master locksmiths can help any homeowner beef up their home security by installing upgraded locks, smart door systems, and more! And, if you’ve got a lockout, our 24-hour locksmith service can help, too. Dispatched in under thirty minutes, our local professional locksmiths give you peace of mind so that you and your family can rest easy knowing you’re safe behind industry standard security.
          </p>
          <p>
            Call us today to speak with one of our residential locksmiths and protect your loved ones with a more secure home!
          </p>
          <h4>Services</h4>
          <ul>
            <li>Lock upgrades</li>
            <li>Lock repairs</li>
            <li>Door Lock Installation</li>
            <li>Rekeying services</li>
            <li>Lockout Service</li>
            <li>Garage Door Locks </li>
            <li>Home Security Systems</li>
            <li>All Lock Brands Serviced</li>
          </ul>
        </div>
        <div className="nav-container">
          <NavPanel />
          <HowCanWeHelpPanel />
        </div>
      </div>
    </div>
  )
}

export default NewHomeContent
