import React from 'react'

import { ReactComponent as AvailableIcon } from 'svgs/available-whenever.svg'
import { ReactComponent as ConciergeIcon } from 'svgs/concierge-service.svg'
import { ReactComponent as RapidResponseIcon } from 'svgs/rapid-response.svg'

import './index.scss'

const Why = () => {
  return (
    <div className="why-container">
      <div className="container">
        <h3>Why Locksmith Directory</h3>
        <div className="panels-container">
          <div className="panel">
            <ConciergeIcon />
            <label>Concierge Service</label>
            <p>Tell us a bit about your needs and we'll find you a locksmith fit.</p>
          </div>
          <div className="panel">
            <RapidResponseIcon />
            <label>Rapid Response</label>
            <p>Get a master locksmith to your home or business in under thirty minutes.</p>
          </div>
          <div className="panel">
            <AvailableIcon />
            <label>Available Whenever You Need</label>
            <p>Our local locksmiths are standing by 24/7 to assist you in your lock servicing needs.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Why
